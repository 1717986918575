import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import { productAreaImageMapping } from "../../themes/gatsby-theme-rothenberger/config/imageMappings"

import { switchedObjectNameOrLabel } from "gatsby-theme-rothenberger/src/utilities/product-data-utils"

import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"
import ProductVariantCard from "gatsby-theme-rothenberger/src/components/molecules/ProductVariantCard"
import HeroBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/HeroBlock"

const ProductAreaTemplate = ({ data }) => {
  const entry = data.entry
  const products = data.products.edges
  const productLines = data.productLines.edges
  const imageMapping = productAreaImageMapping[parseInt(entry?.ID)] || null

  const wideHeaderImagePath = imageMapping?.wide || null
  const normalHeaderImagePath = imageMapping?.normal || null

  let breadcrumbs = [
    { link: entry.rootPage.fullSlug, link_text: entry.rootPage.name },
  ]

  return (
    <Layout language={entry.language}>
      <SEO
        title={`${entry.name} | ${process.env.GATSBY_PRODUCT_AREAS_BASE}`}
        no_description_fallback={true}
        og_image={normalHeaderImagePath}
        language={entry.language}
      />
      <HeroBlock
        block={{
          headline: entry?.name,
          media_height: "sm",
          image: {
            filename: wideHeaderImagePath,
          },
        }}
        breadcrumbs={breadcrumbs}
      />

      <section className="py-block">
        <div className=" block-w-container">
          <div className="flex flex-row flex-wrap -mx-grid">
            <div className="w-full mb-8 md:w-1/3 lg:w-1/4 px-grid">
              <div className="py-4 border border-ro-gray-100">
                {productLines.map((group, index) => {
                  return (
                    <div key={group.node.fullSlug} className="block">
                      <Link
                        className="inline-block py-2 text-sm px-grid hover:text-ro-red"
                        key={index}
                        to={group.node.fullSlug}
                      >
                        {group.node.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="w-full md:w-2/3 lg:w-3/4 px-grid">
              <div className="flex flex-row flex-wrap -mx-grid">
                {products.map((product) => (
                  <div
                    className="flex w-full mb-8 px-grid md:w-1/2 lg:w-1/3"
                    key={product.node.fullSlug}
                  >
                    <ProductVariantCard
                      image={
                        product?.node?.properties?.mainImage !== "undefined"
                          ? product.node.properties.mainImage
                          : { filename: process.env.GATSBY_IMG_PLACEHOLDER }
                      }
                      title={switchedObjectNameOrLabel(product.node)}
                      text={product.node.properties.groupName}
                      link={product.node.fullSlug}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {entry?.properties?.description?.length > 0 && (
        <section>
          <div className="pb-block block-w-container">
            <div className="flex flex-row flex-wrap -mx-grid">
              <div className="w-full lg:w-1/4 px-grid "></div>
              <div className="w-full lg:w-2/4 px-grid ">
                <Richtext text={entry?.properties?.description} />
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export const query = graphql`
  query AreaQuery($id: String, $areaId: String, $locale: String) {
    entry: contentServProductArea(ID: { eq: $id }, locale: { eq: $locale }) {
      name
      externalKey
      language
      ID
      fullSlug
      rootPage {
        name
        fullSlug
      }
      properties {
        description
        mainImage
      }
    }
    products: allContentServProduct(
      filter: { areaId: { eq: $areaId }, locale: { eq: $locale } }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          name
          marketingLabel
          areaId
          groupId
          ID
          treeSorting
          fullSlug
          parentId
          externalKey
          properties {
            mainImage
            media
            groupName
          }
        }
      }
    }
    productLines: allContentServProductLine(
      filter: { areaId: { eq: $areaId }, locale: { eq: $locale } }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          name
          lineId
          ID
          fullSlug
          externalKey
        }
      }
    }
  }
`

export default ProductAreaTemplate
