const productAreaImageMapping = {
  13614: {
    normal:
      "https://a.storyblok.com/f/125728/4724x2399/b58e7b159e/installation_original.jpg",
    wide: "https://a.storyblok.com/f/125728/7558x1527/d6c61ecc4a/installation_1584x320.jpg",
  },

  13615: {
    normal:
      "https://a.storyblok.com/f/125728/4724x2399/768d691611/inspection_original.jpg",
    wide: "https://a.storyblok.com/f/125728/7558x1527/7374840d3d/inspektion_1584x320.jpg",
  },

  13660: {
    normal:
      "https://a.storyblok.com/f/125728/4724x2399/ac69bc8f35/aircon_original.jpg",
    wide: "https://a.storyblok.com/f/125728/7558x1527/16366576c3/aircon_1584x320.jpg",
  },

  13663: {
    normal:
      "https://a.storyblok.com/f/125728/4724x2399/89f4b194bf/universal_original.jpg",
    wide: "https://a.storyblok.com/f/125728/7558x1527/3de63bba93/universal_1584x320.jpg",
  },
}

module.exports = {
  productAreaImageMapping: productAreaImageMapping,
}
